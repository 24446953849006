<template>
  <div id="remoteprovisioning" class="container-fluid" v-if="isAdmin()">
    <h1 class="page-header primary">Remote Configuration Provisioning</h1>

    <div>
      <label for="targetUser" class="f6 b mb2 mr4 w-10">User ID</label>
      <select v-model="input.targetUser">
        <option value="">All Users</option>
        <option v-for="user in users" :value="user.ID" :key="user.ID">
          {{ user.Name }}
        </option>
      </select>
    </div>

    <div>
      <label for="renderundergroundcontent" class="f6 b mb2 mr4 w-10"
        >Render Underground Content</label
      >
      <input
        type="checkbox"
        id="renderundergroundcontent"
        v-model="input.appConfig.RenderUndergroundContent"
      />
    </div>

    <div>
      <label for="showterrain" class="f6 b mb2 mr4 w-10">Show Terrain</label>
      <input
        type="checkbox"
        id="showterrain"
        v-model="input.appConfig.ShowTerrain"
      />
    </div>

    <div>
      <label for="clamptoterrain" class="f6 b mb2 mr4 w-10"
        >Clamp To Terrain</label
      >
      <input
        type="checkbox"
        id="clamptoterrain"
        v-model="input.appConfig.ClampToTerrain"
      />
    </div>

    <div>
      <label for="metersaboveterrain" class="f6 b mb2 mr4 w-10"
        >Meters Above Terrain (if clamped)</label
      >
      <input
        type="number"
        id="metersaboveterrain"
        v-model.number="input.appConfig.MetersAboveTerrain"
      />
    </div>

    <div>
      <label for="usecomputervision" class="f6 b mb2 mr4 w-10"
        >Use Computer Vision</label
      >
      <input
        type="checkbox"
        id="usecomputervision"
        v-model="input.appConfig.UseComputerVision"
      />
    </div>

    <div>
      <label for="sendlivetracking" class="f6 b mb2 mr4 w-10"
        >Send Live Tracking</label
      >
      <input
        type="checkbox"
        id="sendlivetracking"
        v-model="input.appConfig.SendLiveTracking"
      />
    </div>

    <div>
      <label for="maxnumberoftilesperlayer" class="f6 b mb2 mr4 w-10"
        >Max Number Of Tiles Per Layer</label
      >
      <input
        type="number"
        id="maxnumberoftilesperlayer"
        v-model.number="input.appConfig.MaxNumberOfTilesPerLayer"
      />
    </div>

    <div>
      <label for="totaltilemegabytesperlayer" class="f6 b mb2 mr4 w-10"
        >Total Tile MegaBytes Per Layer</label
      >
      <input
        type="number"
        id="totaltilemegabytesperlayer"
        v-model.number="input.appConfig.TotalTileMegaBytesPerLayer"
      />
    </div>

    <div>
      <label for="bingapikey" class="f6 b mb2 mr4 w-10">Bing API Key</label>
      <input type="text" id="bingapikey" v-model="input.appConfig.BingApiKey" />
    </div>

    <div>
      <button
        class="f6 dim primary b bg-white center ph3 pv2 mb2 br3"
        type="button"
        v-on:click="sendAppConfig()"
        :disabled="isAdmin() == false"
      >
        Send App Config
      </button>
    </div>
    <div>
      <input
        id="configJSONFile"
        type="file"
        style="visibility: hidden"
        @change="loadConfig()"
      />
      <button v-on:click="saveConfig()">Save JSON</button>
      <button v-on:click="triggerFileSelect()">Load JSON</button>
    </div>
  </div>
</template>

<script>
import Helpers from '@/helpers.js';
import { mapGetters } from 'vuex';

export default {
  name: 'RemoteProvisioning',
  data() {
    return {
      input: {
        targetUser: '',
        appConfig: {
          RenderUndergroundContent: false,
          ShowTerrain: false,
          ClampToTerrain: false,
          MetersAboveTerrain: 1.524, // = 5ft
          UseSpatialIndexing: false,
          UseComputerVision: true,
          SendLiveTracking: false,
          MaxNumberOfTilesPerLayer: 10,
          TotalTileMegaBytesPerLayer: 30,
          QuickAccessItems: [],
          BingApiKey: '',
        },
      },
    };
  },
  computed: {
    ...mapGetters(['getSessionObj', 'getUsers']),
    users() {
      let myUsers = this.getUsers;
      myUsers.forEach((p) => {
        let user = this.getUsers.find((u) => u.ID === p.OwnerID);
        if (user) {
          p.OwnerName = user.Name;
        }
      });
      return myUsers;
    },
  },
  methods: {
    fetchUsers() {
      this.$store
        .dispatch('apiGetData', {
          url: '/users',
          commit: true,
          stateProperty: 'users',
          responseProperty: 'Items',
          errorAlert: 'ERROR: Could not retrieve users',
        })
        .then((users) => {
          users.Items.sort((a, b) => a.Name.localeCompare(b.Name));
        });
    },
    isAdmin() {
      return Helpers.isAdmin();
    },
    saveConfig() {
      var a = document.createElement('a');
      a.setAttribute(
        'href',
        'data:text/plain;charset=utf-8,' +
          encodeURIComponent(JSON.stringify(this.input.appConfig)),
      );
      a.setAttribute('download', 'AppConfig.json');
      a.click();
    },
    triggerFileSelect() {
      document.getElementById('configJSONFile').click();
    },
    loadConfig() {
      console.log('And we keep going');
      const files = document.getElementById('configJSONFile').files;
      if (files.length <= 0) {
        return false;
      }

      const fr = new FileReader();

      fr.onload = (e) => {
        const result = JSON.parse(e.target.result);
        this.input.appConfig = result;
      };
      fr.readAsText(files.item(0));
    },
    async sendAppConfig() {
      if (this.input.name == '') {
        console.log('A channel name must be present');
        Helpers.showAlert('You must enter a chanenl name');
        return;
      }

      if (Helpers.isTokenExpired(this.getSessionObj.tokenPayload.exp)) {
        Helpers.logout(Helpers.errorMsgTokenExpired());
        return;
      }

      let url = this.getSessionObj.apiURL;
      let requestBody = this.input.appConfig;
      let provisioningURL = '';

      if (this.input.targetUser == '') {
        provisioningURL = '/admin/users/provision';
      } else {
        provisioningURL =
          '/admin/users/' + this.input.targetUser + '/provision';
      }

      // try {
      //   await this.$store.dispatch('apiPostData', {
      //     url: provisioningURL,
      //     commit: false,
      //     body: requestBody,
      //   });
      // } catch (err) {
      //   if (~err.message.search('401')) {
      //     err.message += ' Unauthorized';
      //   }
      //   Helpers.showAlert(err);
      // } finally {
      //   // anything to do here?  This gets called regardless of success or failure
      // }

      this.$http
        .post(url + provisioningURL, requestBody, {
          headers: { authorization: this.getSessionObj.access_token },
        })
        .then(
          function (response) {
            console.log(
              'Success posting to ' + provisioningURL + ' ',
              response.body,
            );
            Helpers.showAlert('Successfully sent App Config to active clients');
          },
          (response) => {
            // Fail
            console.log(
              'Failed POST to ' + provisioningURL + '',
              response.body,
            );
            if (response.body) {
              this.alert = 'Error: ' + response.body;
              Helpers.showAlert('Error: ${response.body}');
            } else {
              Helpers.showAlert('Failed to post to ' + provisioningURL + '');
            }
          },
        );
    },
  },
  created: function () {
    Helpers.doViewCreation(this);
  },
  components: {},
};
</script>

<style scoped></style>
